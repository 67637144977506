import { Browser } from "@capacitor/browser";
// import { isNullOrUndefined } from "util";
import deepEqual from "deep-equal";
import { BuildingListItemDTO, PrivilegeDTO, PropertyStockDTO, PropertyStockSpecialFieldsDTO, RolePrivilegeMap, UserDTO } from "./dto";
import { isEmptyRange } from "./elements/MinMaxField";
import { FormPresenter } from "./hooks";

export interface DiffUpdateSpec<T> {
    updated: T[];
    created: T[];
    deleted: T[];
}

/**
 * Get diff update spec from the difference between two entity list.
 * 
 * @param currentList The list of entities before update
 * @param updatedList The list of entities after update
 * @param isSameEntity Determine whether two objects represent the same entity (e.g. having the same non-null id)
 * @param isEqual Determine whether two objects are deeply equaled. Default to strict deepEqual
 */
export function diffUpdateSpec<T>(currentList: T[], updatedList: T[],
    isSameEntity: (a: T, b: T) => boolean, isEqual: (a: T, b: T) => boolean = (a, b) => deepEqual(a, b, { strict: true })
): DiffUpdateSpec<T> {
    const deleted = currentList.filter(existing => updatedList.every(updated => !isSameEntity(existing, updated)));
    const created = updatedList.filter(existing => currentList.every(updated => !isSameEntity(existing, updated)));
    return {
        deleted,
        created,
        updated: updatedList.filter(existing => {
            return currentList.some(updated => isSameEntity(existing, updated) && !isEqual(existing, updated));
        }),
    };
}

export function trackGoals(goalsToTrack: { goalName: string; customParamList: any; }[]) {


    // if (goalsToTrack?.length > 0) {
    //     goalsToTrack.map(goal => {
    //         window.SCBeacon?.trackGoal(goal);
    //     });
    // }
    if (goalsToTrack?.length > 0) {
        goalsToTrack.map((goal) => {
            const goalName = goal.goalName;
            const customParamList = goal.customParamList;
            if (customParamList?.length > 0) {
                const customParams =
                    customParamList.map(({ paramName, paramValue }: any, index: any) =>
                        index === 0 ? {
                            data: paramValue,
                            dataKey: paramName
                        } : {
                            [paramName]: paramValue
                        }
                    )
                        .reduce((a: any, b: any) => ({ ...a, ...b }), {})
                    ;

                window.SCBeacon?.trackGoal(goalName, customParams);
            } else {
                window.SCBeacon?.trackGoal(goalName);
            }
        });
    }
}

export function isNonEmpty(val: any) {
    if (typeof val === 'undefined' || val === null) {
        return false;
    } else if (Array.isArray(val) && val.length === 0) {
        return false;
    } else if (typeof val === 'string') {
        return val !== '';
    } else if (typeof val === 'number') {
        return val !== 0;
    } else if (typeof val === 'boolean') {
        return val !== false;
    } else {
        return true;
    }
}

export function chineseNumber(num: number, unit: string = '萬') {
    if (unit !== '萬') {
        return num; // not yet implemented
    }

    return num < 10000 ? num + '萬' : (num / 10000).toFixed(2) + '億';
}

export function chineseNumberM(num: number) {
    return [
        [num >= 100_000_000, `${num / 100_000_000}億`],
        // [ 10_000_000 <= num && num < 100_000_000, `${num / 10_000_000}千萬`  ],
        // [ 1_000_000 <= num && num < 10_000_000, `${num / 1_000_000}百萬` ],
        [true, `${num / 10_000}萬`],
    ]
        .filter(tuple => tuple[0])
        .map(tuple => tuple[1])[0];
}

export function objectToQuery(object: any = {}) {
    return Object.keys(object).filter((key) => {
        return object[key] !== null &&
            object[key] !== undefined &&
            (!Array.isArray(object[key]) || (object[key].length > 0 && !isEmptyRange(object[key]))) &&
            isNonEmpty(object[key]);
    }).map((key) => {
        let value = object[key];
        if (Array.isArray(value)) {
            value = value.map(item => item !== null && item !== undefined ? item : '').join(',');
        } else if (typeof value === 'object') {
            return Object.keys(value).map(subKey => `${key}=${encodeURIComponent(subKey)},${encodeURIComponent(value[subKey])}`).join('&');
        }
        return `${key}=${encodeURIComponent(value)}`;
    }).join('&');
}

export function handlePriceDisplay(price: number, locale: string) {
    if (locale === 'en') {
        if (price >= 1000 * 1_000_000) {
            return (price / (1000 * 1_000_000));
        } else {
            return (price / 1_000_000);
        }
    } else {
        if (price >= 10_000 * 10_000) {
            return (price / (10_000 * 10_000));
        } else {
            return (price / 10_000);
        }
    }
}

export function handlePriceDisplayUnit(price: number, locale: string, lang: LocaleOptions) {
    if (locale === 'en') {
        if (price >= 1000 * 1_000_000) {
            return lang.u1B;
        } else {
            return lang.u1M;
        }
    } else {
        if (price >= 10_000 * 10_000) {
            return lang.u100M;
        } else {
            return lang.u10k;
        }
    }
}

export function priceToView(price: number, locale: string) {
    if (locale === 'en') {
        return price / 1_000_000
    } else {
        return price / 10_000;
    }
}

export function priceFromView(price: number, locale: string) {
    if (locale === 'en') {
        return price * 1_000_000
    } else {
        return price * 10_000;
    }
}

export function multiLang(locale: string, zhDisplay: string | undefined, enDisplay: string | undefined) {
    return locale === 'en' ? (enDisplay || zhDisplay) : (zhDisplay || enDisplay);
}

export function handleBlockDisplay(locale: string, blockStr: string) {
    if (!isNonEmpty(blockStr)) {
        return '';
    } else { 
        return locale === 'en' ? 'Block ' + blockStr : blockStr + '座';
    }
}

//may change param to propertyStockDto later
export function genAddress(locale: string, unit: string, floor: string, block: string, blockEn: string, building: string, buildingEn: string, street: string, streetEn: string, district: string) {
    let address = [];
    let addressString = '';
    if (locale == 'en') {
        address.push(!isNonEmpty(unit) ? '' : 'Unit ' + unit);
        address.push(!isNonEmpty(floor) ? '' : floor + '/F');
        address.push(handleBlockDisplay('en', multiLang(locale, block, blockEn) ?? ''));
        address.push(multiLang(locale, building, buildingEn) ?? '');
        address.push(multiLang(locale, street, streetEn) ?? '');
    } else {
        address.push(!isNonEmpty(unit) ? '' : unit + '室');
        address.push(!isNonEmpty(floor) ? '' : floor + '樓');
        address.push(handleBlockDisplay('zh_HK', multiLang(locale, block, blockEn) ?? ''));
        address.push(multiLang(locale, building, buildingEn) ?? '');
        address.push(multiLang(locale, street, streetEn) ?? '');
    }
    address.push(district ?? '');

    address = address.filter(v => isNonEmpty(v));

    if (locale == 'en') {
        addressString = address.join(', ');
    } else {
        addressString = address.reverse().join('');
    }
    return addressString;
}

export function checkValidPhoneNumber(phoneNumber: string) {
    // const re = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$");
    const re = /^((\([+]{0,1}[0-9]{1,4}\))|[+])?[-\s\./0-9]+[0-9]$/;
    // const re = new RegExp("^[(]{0,1}[+]{0,1}[0-9]{1,4}[)]{0,1}[\\s/0-9]*$");
    if (re.test(phoneNumber)) {
        return true;
    }
    return false;
}

export function checkValidEmail(email: string) {
    let re = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/;
    // const re = new RegExp('/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/igm');
    if (re.test(email)) {
        return true;
    }
    return false;
}

export function openNewWindow(path: string) {

    Browser.open({ url: path });

}

//return error msg
export const getInvalidContactErrMsg = (invalidContactsPhoneNum: string | undefined, invalidContactsEmail: string | undefined, emptyContactValue: string | undefined, idx: number, type: string, langClientDetail: any) => {
    const invalidContactsPhoneNumArray = invalidContactsPhoneNum?.split('_') ?? [];
    const invalidContactsEmailArray = invalidContactsEmail?.split('_') ?? [];
    const emptyContactValueArray = emptyContactValue?.split('_') ?? [];
    if (type === 'TEL') {
        return invalidContactsPhoneNumArray.includes(idx.toString()) ? langClientDetail.msgInputValidPhoneNumber : null;
    } else if (type === 'EMAIL') {
        return invalidContactsEmailArray.includes(idx.toString()) ? langClientDetail.msgInputValidEmail : null;
    } else {
        return emptyContactValueArray.includes(idx.toString()) ? langClientDetail.msgPleaseInputContact : null;
    }
    return null;
};

export const autoFillNameAndLicenseNumberOfAgent = (formName: string, locale: string, user: Partial<UserDTO>) => {
    const nameAndLicenseNumberOfAgent = multiLang(locale, user.chineseName, user.englishName) + " " + (user.licenseNumber ?? '');
    switch (formName) {
        case 'form1':
        case 'form2':
            return {
                nameAndLicenceNumberOfSignatory: nameAndLicenseNumberOfAgent,
            };
        case 'form3':
        case 'form4':
        case 'form5':
        case 'form6':
            return {
                nameAndLicenceNumberOfAgent: nameAndLicenseNumberOfAgent,
            };
        default:
            return {};
    }
}

export const autoFillCompanyInfo = (formName: string, locale: string, companyInfo: { [key: string]: string }) => {
    switch (formName) {
        case 'form1':
        case 'form2':
            return {
                agentName: multiLang(locale, companyInfo.COMPANY_NAME_CN, companyInfo.COMPANY_NAME_EN),
                numberOfAgentStatement: companyInfo.STMT_OF_BUSINESS,
                address: multiLang(locale, companyInfo.ADDRESS_CN, companyInfo.ADDRESS_EN),
                telephoneNumber: companyInfo.PHONE_NUM,
                faxNumber: companyInfo.FAX_NUM,
            };
        case 'form3':
        case 'form4':
        case 'form5':
        case 'form6':
            return {
                agentName: multiLang(locale, companyInfo.COMPANY_NAME_CN, companyInfo.COMPANY_NAME_EN),
                numberOfStatementOfParticularsOfBusiness: companyInfo.STMT_OF_BUSINESS,
                agentAddress: multiLang(locale, companyInfo.ADDRESS_CN, companyInfo.ADDRESS_EN),
                agentPhoneNumber: companyInfo.PHONE_NUM,
                agentFaxNumber: companyInfo.FAX_NUM,
            };
        case 'tenancyAgreement':
        case 'salePurchaseAgreement':
            return {
                agentName: multiLang(locale, companyInfo.COMPANY_NAME_CN, companyInfo.COMPANY_NAME_EN),
                agentBusinessRegNo: companyInfo.BUSINESS_REG_NO,
                agentLicenceNo: companyInfo.LICENCE_NO,
                agentAddress: multiLang(locale, companyInfo.ADDRESS_CN, companyInfo.ADDRESS_EN),
            };
        default:
            return {};
    }
}

export const showClientContact = (isCompanyClient: boolean, canReadCompanyClient: boolean,
    canReadOwnedClient: boolean,
    isOwnedClient: boolean) => {
    if (isCompanyClient === true) {
        return canReadCompanyClient;
    }
    else return canReadOwnedClient && isOwnedClient
}

/** Reset &lt;input type="file"> value */
export const resetFileInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.target.value = '';
    if (!/safari/i.test(navigator.userAgent)) {
        ev.target.type = '';
        ev.target.type = 'file';
    }
    return true;
}

export const colPrefJsonWrapper = (rowsCount: number, columnsOrder: string[], disabledColumns: string[], columnsCannotBeHidden: string[]) => {
    const obj0 = { rowsCount: rowsCount };
    const obj1 = { columnsOrder: columnsOrder };
    const obj2 = { disabledColumns: disabledColumns };
    const obj3 = { columnsCannotBeHidden: columnsCannotBeHidden };

    return Object.assign({}, obj0, obj1, obj2, obj3);
}

export const initialRowsCountPreference = {
    client: 10,
    propertyStock: 10,
    transaction: 10,
    building: 10,
    memoEnquiry: 10,
    salesCommissionReport: 10,
    notificationCenter: 10
};

export const initialPsColOrder = [
    'id',
    'dateCreated',
    'dateModified',
    'status',
    'usage',
    'street',
    'building',
    'price',
    'block',
    'floor',
    'unit',
    'gross',
    'net',
    'pricePerGross',
    'pricePerNet',
    'owner',
    'mainContact',
    'lot',
    'symbol',
    'keyNo',
    'vacant',
    'occupancyPermitAge'
];

export const initialClientColOrder = [
    'clientType',
    'clientStatus',
    'clientName',
    'contactType',
    'mainContact',
    'source',
    'usage',
    'net',
    'price',
    'remarks',
    'dateModified'
];

export const convertToPrivilegeDtoList = (roleId: string | undefined, form: FormPresenter<RolePrivilegeMap>) => {
    let privilegeDtoList: PrivilegeDTO[] = [];
    if (!isNonEmpty(roleId)) {
        return [];
    }
    const formValues = form.values;
    Object.keys(formValues).map((resourceName) => {
        const operationsList = Object.keys(form.values[resourceName] ?? {});
        operationsList?.map((operation: string) => {
            const privilegeId = form.values[resourceName]?.[operation] ?? -1;
            if (privilegeId > -1) {
                privilegeDtoList.push({
                    id: formValues[resourceName]?.[operation],
                    operation: operation,
                    resource: resourceName,
                    role: roleId,
                } as PrivilegeDTO);
            }
        })
    })
    return privilegeDtoList;
}

export const numberWithCommas = (x: number | string | undefined) => {
    // return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return x !== undefined ? parseFloat(String(x)).toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 20 }) : undefined;
}



export const getBuilingListWithoutDuplicates = (buildings: BuildingListItemDTO[]) => {
    const seen = new Set();
    const filteredArr = buildings.filter(building => {
        const duplicate = seen.has(building.building);
        seen.add(building.building);
        return !duplicate;
    });
    return filteredArr;
}


export const getStreetListWithoutDuplicates = (streets: BuildingListItemDTO[]) => {
    const seen = new Set();
    const filteredArr = streets.filter(street => {
        const duplicate = seen.has(street.street);
        seen.add(street.street);
        return !duplicate;
    });
    return filteredArr;
}

//http://hknothingblog.blogspot.com/2013/01/javascript-to-validate-hkid-number.html
export const checkIsHKID = (str: string) => {
    var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    // basic check length
    if (str.length < 8)
        return false;

    //check pattern including '(' and ')'
    var hkidPatFull = /^([A-Z]{1,2})([0-9]{6})\(([A0-9])\)$/;
    var matchArrayFull = str.match(hkidPatFull);
    if (matchArrayFull == null)
        return false;

    // handling bracket
    if (str.charAt(str.length - 3) == '(' && str.charAt(str.length - 1) == ')')
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

    // convert to upper case
    str = str.toUpperCase();

    // regular expression to check pattern and split
    var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
    var matchArray = str.match(hkidPat);

    // not match, return false
    if (matchArray == null)
        return false;

    // the character part, numeric part and check digit part
    var charPart = matchArray[1];
    var numPart = matchArray[2];
    var checkDigit = matchArray[3];

    // calculate the checksum for character part
    var checkSum = 0;
    if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
    } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
    }

    // calculate the checksum for numeric part
    for (var i = 0, j = 7; i < numPart.length; i++, j--)
        checkSum += j * parseInt(numPart.charAt(i));

    // verify the check digit
    var remaining = checkSum % 11;
    var verify = remaining == 0 ? 0 : 11 - remaining;

    return verify.toString() == checkDigit || (verify == 10 && checkDigit == 'A');
}

export const getPropertyStockSpecialFieldsDTO = (stockForm: Partial<PropertyStockDTO>) => {
    const resultDto = {
        // address
        lot: stockForm.lot,
        street: stockForm.street,
        streetEn: stockForm.streetEn,
        usage: stockForm.usage,
        unit: stockForm.unit,
        floor: stockForm.floor,

        // basics
        gross: stockForm.gross,
        net: stockForm.net,
        currentRent: stockForm.currentRent,
        leaseCommencement: stockForm.leaseCommencement, // lease period from 
        leaseExpiry: stockForm.leaseExpiry, // lease period to
        vacant: stockForm.vacant, // 交吉
        hasKey: stockForm.hasKey,
        keyNo: stockForm.keyNo,

        owner: stockForm.owner,

        // Property Summary
        headingEN: stockForm.headingEN,
        headingTC: stockForm.headingTC,
        headingSC: stockForm.headingSC,
        punchlineEN: stockForm.punchlineEN,
        punchlineTC: stockForm.punchlineTC,
        punchlineSC: stockForm.punchlineSC,

        // Single Option Features
        facing: stockForm.facing,
        deco: stockForm.deco,
        room: stockForm.room,
        suite: stockForm.suite,
        livingRoom: stockForm.livingRoom,
        helperRoom: stockForm.helperRoom,
        bathroom: stockForm.bathroom,
        balcony: stockForm.balcony,
        balconySizes: stockForm.balconySizes,
        hasGarden: stockForm.hasGarden,
        gardenArea: stockForm.gardenArea,
        hasRooftop: stockForm.hasRooftop,
        rooftopArea: stockForm.rooftopArea,
        primarySchoolNet: stockForm.primarySchoolNet,
        secondarySchoolNet: stockForm.secondarySchoolNet,

        //////Shop related fields
        baseFloorGross: stockForm.baseFloorGross,
        baseFloorNet: stockForm.baseFloorNet,
        groundFloorGross: stockForm.groundFloorGross,
        groundFloorNet: stockForm.groundFloorNet,
        mezzanineFloorGross: stockForm.mezzanineFloorGross,
        mezzanineFloorNet: stockForm.mezzanineFloorNet,
        otherAreaGross: stockForm.otherAreaGross,
        otherAreaNet: stockForm.otherAreaNet,
        rentFreePeriod: stockForm.rentFreePeriod,
        _yield: stockForm._yield,

        toilet: stockForm.toilet,
        backDoor: stockForm.backDoor,
        mezzanineFloorArea: stockForm.mezzanineFloorArea,
        storeFrontWidth: stockForm.storeFrontWidth,
        storeFrontHeight: stockForm.storeFrontHeight,
        shopDepth: stockForm.shopDepth,

        videoLink: stockForm.videoLink,

    } as PropertyStockSpecialFieldsDTO;

    return resultDto;
}

export const getBuildingSortForAutoComplete = (locale: string) => {
    return { [locale === 'en' ? 'buildingNameEn' : 'buildingNameZh']: 'asc', [locale === 'en' ? 'buildingNameZh' : 'buildingNameEn']: 'asc' };
}

export const getStreetSortForAutoComplete = (locale: string) => {
    return { [locale === 'en' ? 'streetEn' : 'streetZh']: 'asc', [locale === 'en' ? 'streetZh' : 'streetEn']: 'asc' };
}

export const isBlankObject = (obj: object | null | undefined): obj is (null | undefined) => {
    return Object.keys(obj ?? {}).length === 0;
}

export const numericValue = (str: number | string | undefined | null): number | undefined => {
    if (!str?.toString()?.trim()) {
        return undefined;
    }

    return isNaN(+str) ? undefined : +str;
}


export const filterUndefinedFields = <T,>(values: T) => {
    let filtered: T = {} as T;
    if (!values) return filtered;
    Object
        .keys(values)
        .filter((key) => (values[key as keyof T] !== undefined && values[key as keyof T] !== null))
        .forEach((key) => {
            filtered[key as keyof T] = values[key as keyof T];
        });
    return filtered;
};

export const restrictDecimal = (number: string, locale: string) => {
    return number.indexOf(".") + 1 > 0 ?
               /*then if */ (number.length - (number.indexOf(".") + 1)) > 4 ?
               /*then if */ locale === 'zh_HK' ?
               /*then */ (Math.floor(parseFloat(number) * 10000) / 10000).toFixed(4) :
               /*else if */(number.length - (number.indexOf(".") + 1)) > 6 ?
               /*then */ (Math.floor(parseFloat(number) * 1000000) / 1000000).toFixed(6) :
               /*else */ number :
               /*else */ number :
               /*else */ parseInt(number)
}

export const limitTextFieldLength = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, limitedLength: number, value: string, form: any, keyBooleanMap?: Map<string, boolean>, setKeyBooleanMap?: React.Dispatch<React.SetStateAction<Map<string, boolean>>>) => {

    if (keyBooleanMap && setKeyBooleanMap) {
        setKeyBooleanMap(keyBooleanMap.set(value, e.target.value.length > limitedLength))
    }
    if (e.target.value.length > limitedLength) {
        e.preventDefault();
        form.bind(value).onChange({ target: { value: e.target.value.substring(0, limitedLength) } });
    } else {
        form.bind(value).onChange(e);
    }
}

export function handlePriceLength(price: number) {
    if (price % 1 === 0 || price / 1000000000000 >= 1) {
        return price.toFixed(0);
    }
    else {
        return price.toFixed(12 - (price.toString().substring(0, price.toString().indexOf("."))).length);
    }
}

export const limitNumberFieldLength = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string, form: any) => {
    if (!isNonEmpty(e.target.value)) {
        form.updateValues(value, '');
    } else if (isNaN(parseFloat(e.target.value))) {
        e.preventDefault();
    } else if (e.target.value.match(/^\d*\.?\d*$/) == null) {
        // Restrict input special character after number
        e.preventDefault();
    } else if (e.target.value.indexOf('.') != -1 && e.target.value.match(/\./g)!.length >= 2) {
        // (Not Dot (Integer) and length>12) OR (Exist more than 1 Dot)
        e.preventDefault();
    } else if ((e.target.value.indexOf(".") == -1 && e.target.value.length > 12) || (e.target.value.indexOf(".") != -1 && e.target.value.indexOf(".") > 12)) {
        // (No Dot and length of value >12) OR (Exist Dot and position of Dot >12)
        e.preventDefault();
    } else {
        form.updateValues(value, e.target.value.indexOf(".") == -1 ? e.target.value : (e.target.value.length - (e.target.value.indexOf(".") + 1)) > 2 ? (Math.floor(parseFloat(e.target.value) * 100) / 100).toFixed(2) : e.target.value);
    }
}

export const initFormKeysAndBooleanMap = (form: any) => {
    let map = new Map<string, boolean>();
    Object.keys(form.values).forEach(key => {

        if (Array.isArray(form.values[key])) {
            form.values[key].forEach((_: any, i: number) => map.set(key + i, false))
        } else {
            map.set(key, false)
        };
    })

    return map;
}
